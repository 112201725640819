import { BillingPlatform, DBAccessProductType } from 'lux/models/price/product.types';
import { getApiCount, getContactsCountForPlatform } from 'src/utils/price/products';

export interface AnalyticParams {
    apiCount: number;
    civCount: number;
    priceRegionId: string;
    period: string;
    employerId: string;
    price: DBAccessProductType['price'];
    tariff?: string;
}
const getAnalyticsParams = (product: DBAccessProductType, employerId: string): AnalyticParams => {
    const { period, region, price, tariff } = product;
    const apiCount = getApiCount(product);
    const contactsCountHh = getContactsCountForPlatform(product, BillingPlatform.HH);

    return {
        apiCount: Number(apiCount),
        civCount: Number(contactsCountHh),
        priceRegionId: region,
        period,
        employerId,
        price,
        tariff,
    };
};

export default getAnalyticsParams;
