import React from 'react';

import CustomSelect, { CustomSelectOption } from 'bloko/blocks/customSelect';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SearchPeriodOption, PossibleSearchPeriod } from 'lux/models/resumeSearchCriteria';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

interface SearchPeriodProps {
    onChange: (value: PossibleSearchPeriod) => void;
    value: PossibleSearchPeriod;
}

const TrlKeys = {
    customInterval: 'search.period.-1',
    [SearchPeriodOption.AllTime]: 'search.period.0',
    [SearchPeriodOption.Day]: 'search.period.1',
    [SearchPeriodOption.ThreeDays]: 'search.period.3',
    [SearchPeriodOption.Week]: 'search.period.7',
    [SearchPeriodOption.Month]: 'search.period.30',
    [SearchPeriodOption.Year]: 'search.period.365',
};

const SearchPeriod: TranslatedComponent<SearchPeriodProps> = ({ onChange, value, trls }) => {
    const values = useSelector((state) => state.resumeSearchDictionaries.search_period); // eslint-disable-line camelcase

    if (!values) {
        return null;
    }

    const searchPeriodValues = value === 'customInterval' ? [...values, value] : values;

    return (
        <CustomSelect
            name="search_period"
            onChange={onChange}
            wrapperProps={{ 'data-qa': 'serp-settings__search-period' }}
            value={value}
            light
            flexible
        >
            {searchPeriodValues.map((value) => (
                <CustomSelectOption data-qa={`search-period-${value}`} key={value} value={value}>
                    {trls[TrlKeys[value]]}
                </CustomSelectOption>
            ))}
        </CustomSelect>
    );
};

export default translation(SearchPeriod);
