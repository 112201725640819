import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { H3Section } from 'bloko/blocks/header';
import { CrossScaleMediumEnclosedFalse, IconLink } from 'bloko/blocks/icon';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { setShowResumeCardImprovedOnboardingBanner } from 'lux/models/showResumeCardImprovedOnboardingBanner';
import ElementShownAnchor from 'src/components/ElementShownAnchor';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

import styles from './index.less';

const REMOTE = '/shards/close_resume_card_improved_onboarding_banner';

declare global {
    interface FetcherPutApi {
        [REMOTE]: {
            queryParams: undefined;
            body: undefined;
            response: void;
        };
    }
}

const TrlKeys = {
    title: 'resume_card_improved.onboarding.banner.title',
    text: 'resume_card_improved.onboarding.banner.text',
};

type AnalyticsParams = {
    employerId: string;
    employerManagerId?: string;
};

const sendEventElementShown = (element: HTMLElement, analyticsParams: AnalyticsParams) =>
    Analytics.sendHHEventElementShown(element, {
        elementName: 'resume_card_improved_onboarding_banner',
        ...analyticsParams,
    });

const sendEventClose = (analyticsParams: AnalyticsParams) =>
    Analytics.sendHHEventButtonClick('resume_card_improved_onboarding_banner_close', analyticsParams);

const ResumeCardImprovedOnboardingBanner: TranslatedComponent = ({ trls }) => {
    const dispatch = useDispatch();

    const employerId = useSelector((state) => state.employerId);
    const employerManagerId = useSelector((state) => state.employerManager?.id);

    const handleClick = useCallback(() => {
        dispatch(setShowResumeCardImprovedOnboardingBanner(false));
        fetcher.put(REMOTE).catch(console.error);
        sendEventClose({ employerId, employerManagerId });
    }, [dispatch, employerId, employerManagerId]);

    const isZP = useIsZarplataPlatform();

    return (
        <ElementShownAnchor fn={sendEventElementShown} employerId={employerId} employerManagerId={employerManagerId}>
            <div className={classnames(styles.wrapper, { [styles.wrapperZp]: isZP })}>
                <div className={styles.container}>
                    <div className={styles.content}>
                        <H3Section>{trls[TrlKeys.title]}</H3Section>
                        <VSpacing base={2} />
                        <Text>{trls[TrlKeys.text]}</Text>
                    </div>
                    <div className={styles.image} />
                    <IconLink onClick={handleClick}>
                        <CrossScaleMediumEnclosedFalse />
                    </IconLink>
                </div>
            </div>
        </ElementShownAnchor>
    );
};

export default translation(ResumeCardImprovedOnboardingBanner);
