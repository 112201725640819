import { AxiosError, AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { CONFLICT } from 'http-status-codes';
import { AnyAction, Dispatch } from 'redux';

import { makeSetStoreField, NonNullableRootState } from '@hh.ru/redux-create-reducer';

import Debug from 'HHC/Debug';
import { updateUrl } from 'Modules/url';
import { AuctionData, CreatedAuctionData, CurrentVacancyAuctionCampaign } from 'lux/models/auctionData';
import { ProductName } from 'lux/models/clickme/clickmeProducts';
import { PriceCart } from 'lux/models/price/priceCart';
import { ProductType } from 'lux/models/price/product.types';
import MetallicVacancyType from 'lux/models/vacancy/metallicVacancyType.types';
import {
    AutoCampaignDraftResponseData,
    AutoCampaignResponseData,
    CampaignData,
    PromotionAutoCampaignRequestData,
} from 'src/components/Clickme/AutoCampaign/utils';
import defaultError from 'src/components/Notifications/DefaultError';
import { auctionError, AuctionErrorType } from 'src/components/Notifications/EmployerAuction';
import addToCartErrorNotification from 'src/components/Notifications/PriceCartError';
import type { AddNotification } from 'src/components/Notifications/Provider/types';
import saveSuccessfullyAuctionModalNotification from 'src/components/Notifications/VacancyAuctionSaveSuccessfullNotification';
import fetcher from 'src/utils/fetcher';

import addToCartAnimation from 'lux/requests/price/addToCartAnimation';

const priceCartAction = makeSetStoreField('priceCart');

declare global {
    interface FetcherPostApi {
        '/shards/price/cart/items': {
            queryParams: {
                employerId?: string;
            };
            body: {
                product: ProductType;
            };
            response: { priceCart: PriceCart };
        };
    }
    interface FetcherPostApi {
        '/shards/price/cart/item_list': {
            queryParams: {
                employerId?: string;
            };
            body: {
                productList: ProductType[];
            };
            response: { priceCart: PriceCart };
        };
    }
    interface FetcherDeleteApi {
        '/shards/price/cart/item': {
            queryParams: {
                itemId: string;
                employerId?: string;
            };
            response: { priceCart: PriceCart };
        };
    }
    interface FetcherPostApi {
        '/shards/price/cart/purchase': {
            queryParams: {
                employerId?: string;
            };
            body: null;
            response?: { purchaseUrl: string };
        };
    }
    interface FetcherPostApi {
        '/shards/employer/create_cart': {
            queryParams: {
                employerId?: string;
            };
            body: {
                products: ProductType[];
                purchaseParams: Record<string, string>;
            };
            response: { purchaseUrl: string };
        };
    }
    interface FetcherPostApi {
        '/shards/employer/create_cart_autoaction_upgrade': {
            queryParams: {
                employerId?: string;
            };
            body: {
                products: ProductType[];
                metallic: MetallicVacancyType;
                purchaseParams: Record<string, string>;
                vacancyId: string;
            };
            response: { purchaseUrl: string };
        };
    }
    interface FetcherPostApi {
        '/shards/employer/clickme_auto_campaign/create_cart_with_clickme_auto_campaign_draft': {
            queryParams: void;
            body: {
                products: ProductType[];
                purchaseParams: Record<string, string>;
                vacancyId: string;
                title: string;
                text: string;
                type: ProductName;
            };
            response: AutoCampaignDraftResponseData;
        };
    }
    interface FetcherPutApi {
        '/shards/employer/clickme_auto_campaign/update_cart_with_clickme_auto_campaign_draft': {
            queryParams: void;
            body: {
                products: ProductType[];
                purchaseParams: Record<string, string>;
                vacancyId: string;
                title: string;
                text: string;
                type: ProductName;
                campaignDraftId: string;
            };
            response: AutoCampaignDraftResponseData;
        };
    }
    interface FetcherPostApi {
        '/shards/employer/clickme_auto_campaign/promotion_create': PromotionAutoCampaignRequestData;
        '/shards/employer/clickme_auto_campaign/promotion_validate': PromotionAutoCampaignRequestData;
    }
    interface FetcherPostApi {
        '/shards/vacancy/auction/create_cart_with_auction_campaign_draft': {
            queryParams: void;
            body: {
                products: ProductType[];
                purchaseParams: Record<string, string>;
                vacancyId: string;
                bid: number;
                budget: number;
            };
            response: { purchaseUrl: string };
        };
    }
    interface FetcherPutApi {
        '/shards/vacancy/auction/update_cart_with_auction_campaign_draft': {
            queryParams: void;
            body: {
                products: ProductType[];
                purchaseParams: Record<string, string>;
                vacancyId: string;
                bid: number;
                budget: number;
                campaignDraftId: string;
            };
            response: { purchaseUrl: string };
        };
    }
    interface FetcherPostApi {
        '/shards/vacancy/auction/create_auction_campaign_without_purchase': {
            queryParams: void;
            body: {
                vacancyId: string;
                bid: number;
                budget: number;
            };
            response: CreatedAuctionData;
        };
    }
    interface FetcherGetApi {
        '/shards/price/cart/get_items': {
            queryParams: {
                employerId?: string;
            };
            response: { priceCart: PriceCart };
        };
    }
    interface FetcherPutApi {
        '/shards/vacancy/auction/create_cart_with_changed_clickme_campaign': {
            queryParams: void;
            body: {
                products: ProductType[];
                purchaseParams: Record<string, string>;
                vacancyId: string;
                bid: number;
                budget: number;
                campaignId: number;
            };
            response: { purchaseUrl: string };
        };
    }
    interface FetcherPutApi {
        '/shards/vacancy/auction/save_auction_campaign': {
            queryParams: {
                vacancyId: string;
                campaignId: number;
                budget: number;
                bid: number;
            };
            body: null;
            response: AuctionData;
        };
    }
    interface FetcherPutApi {
        '/shards/vacancy/auction/save_and_restore_auction_campaign': {
            queryParams: {
                vacancyId: string;
                campaignId: number;
                budget: number;
                bid: number;
            };
            body: null;
            response: Partial<CurrentVacancyAuctionCampaign>;
        };
    }
}

export const addItem =
    (product: ProductType, target: EventTarget, addNotification: AddNotification) =>
    (dispatch: Dispatch<AnyAction>, getState: () => NonNullableRootState): Promise<void> => {
        const employerId = getState().priceData.priceEmployerId;
        return fetcher.post('/shards/price/cart/items', { product }, { params: { employerId } }).then(
            ({ data }) => {
                if (data.priceCart) {
                    dispatch(priceCartAction(data.priceCart));
                    addToCartAnimation(target);
                } else {
                    addNotification(addToCartErrorNotification);
                }
            },
            () => {
                addNotification(addToCartErrorNotification);
            }
        );
    };

export const addItemList =
    (productList: ProductType[], target: EventTarget, addNotification: AddNotification) =>
    (dispatch: Dispatch<AnyAction>, getState: () => NonNullableRootState): Promise<void> => {
        const employerId = getState().priceData.priceEmployerId;
        return fetcher.post('/shards/price/cart/item_list', { productList }, { params: { employerId } }).then(
            ({ data }) => {
                if (data.priceCart) {
                    dispatch(priceCartAction(data.priceCart));
                    addToCartAnimation(target);
                } else {
                    addNotification(addToCartErrorNotification);
                }
            },
            () => {
                addNotification(addToCartErrorNotification);
            }
        );
    };

export const removeItem =
    (itemId: string, addNotification: AddNotification) =>
    (dispatch: Dispatch<AnyAction>, getState: () => NonNullableRootState): Promise<void> => {
        const employerId = getState().priceData.priceEmployerId;
        return fetcher.delete(`/shards/price/cart/item`, { params: { itemId, employerId } }).then(
            ({ data }) => {
                if (data.priceCart) {
                    dispatch(priceCartAction(data.priceCart));
                } else {
                    addNotification(defaultError);
                }
            },
            () => {
                addNotification(defaultError);
            }
        );
    };

export const getItems =
    (addNotification: AddNotification) =>
    (dispatch: Dispatch<AnyAction>, getState: () => NonNullableRootState): Promise<void> => {
        const employerId = getState().priceData.priceEmployerId;
        return fetcher.get('/shards/price/cart/get_items', { params: { employerId } }).then(
            ({ priceCart }) => {
                if (priceCart) {
                    dispatch(priceCartAction(priceCart));
                } else {
                    addNotification(defaultError);
                }
            },
            () => {
                addNotification(defaultError);
            }
        );
    };

interface PurchaseParams {
    hhtmFromLabel: string;
}

export const purchase =
    ({ hhtmFromLabel }: PurchaseParams, addNotification: AddNotification) =>
    (dispatch: Dispatch<AnyAction>, getState: () => NonNullableRootState): Promise<void> => {
        const employerId = getState().priceData.priceEmployerId;
        const loginUrl = getState().authUrl['login-url'];
        return fetcher.post('/shards/price/cart/purchase', null, { params: { employerId } }).then(
            (response) => {
                if (response.data?.purchaseUrl) {
                    dispatch(
                        push(
                            updateUrl(response.data.purchaseUrl, {
                                hhtmFromLabel,
                            })
                        )
                    );
                } else {
                    Debug.log('out error', new Error('Missing purchaseUrl'));
                }
            },
            ({ response }: { response: AxiosResponse }) => {
                if (response.status === 403) {
                    dispatch(push(loginUrl));
                } else {
                    addNotification(defaultError);
                }
            }
        );
    };

export const createCart =
    (
        products: ProductType[],
        addNotification: AddNotification,
        purchaseParams: Record<string, string> = {},
        employerId?: string
    ) =>
    (dispatch: Dispatch<AnyAction>): Promise<void> => {
        return fetcher
            .post('/shards/employer/create_cart', { products, purchaseParams }, { params: { employerId } })
            .then(
                ({ data }) => {
                    if (data.purchaseUrl) {
                        dispatch(push(updateUrl(data.purchaseUrl)));
                    } else {
                        addNotification(defaultError);
                    }
                },
                () => {
                    addNotification(defaultError);
                }
            );
    };

export const createCartAutoActionUpgrade =
    (
        products: ProductType[],
        metallic: MetallicVacancyType,
        addNotification: AddNotification,
        purchaseParams: Record<string, string> = {},
        vacancyId: string,
        employerId?: string
    ) =>
    (dispatch: Dispatch<AnyAction>): Promise<void> => {
        return fetcher
            .post(
                '/shards/employer/create_cart_autoaction_upgrade',
                { products, metallic, purchaseParams, vacancyId },
                { params: { employerId } }
            )
            .then(
                ({ data }) => {
                    if (data.purchaseUrl) {
                        dispatch(push(updateUrl(data.purchaseUrl)));
                    } else {
                        addNotification(defaultError);
                    }
                },
                () => {
                    addNotification(defaultError);
                }
            );
    };

export const createOrUpdateCartWithClickmeAutoCampaignDraft = async (
    products: ProductType[],
    purchaseParams: Record<string, string> = {},
    vacancyId: string,
    campaignData: CampaignData,
    campaignDraftId: string | undefined,
    onSuccess: (autoCampaignDraftResponseData: AutoCampaignDraftResponseData) => void,
    onError: () => void
): Promise<void> => {
    const { activeProductType, title, text } = campaignData;

    const body = {
        products,
        purchaseParams,
        vacancyId,
        title,
        text,
        type: activeProductType,
    };

    try {
        let res;

        if (campaignDraftId) {
            res = await fetcher.put(
                '/shards/employer/clickme_auto_campaign/update_cart_with_clickme_auto_campaign_draft',
                {
                    ...body,
                    campaignDraftId,
                }
            );
        } else {
            res = await fetcher.post(
                '/shards/employer/clickme_auto_campaign/create_cart_with_clickme_auto_campaign_draft',
                body
            );
        }
        const { data } = res;
        if (data) {
            onSuccess(data);
        } else {
            onError();
        }
    } catch (e) {
        onError();
    }
};

export const createClickmeAutoCampaignWithoutPurchase = (
    vacancyId: number,
    campaignData: CampaignData,
    isOnlyValidation: boolean,
    onSuccess: (autoCampaignRequestData: AutoCampaignResponseData) => void,
    onError: () => void
): Promise<void> => {
    const { activeProductType, title, text } = campaignData;

    return fetcher
        .post(
            isOnlyValidation
                ? '/shards/employer/clickme_auto_campaign/promotion_validate'
                : '/shards/employer/clickme_auto_campaign/promotion_create',
            {
                vacancyId,
                title,
                text,
                type: activeProductType,
            }
        )
        .then(
            ({ data }) => {
                if (data) {
                    onSuccess(data);
                } else {
                    onError();
                }
            },
            () => {
                onError();
            }
        );
};

export const createOrUpdateCartWithClickmeAuctionCampaignDraft =
    (
        products: ProductType[],
        purchaseParams: Record<string, string> = {},
        vacancyId: string,
        bid: number,
        budget: number,
        addNotification: AddNotification,
        campaignDraftId?: string
    ) =>
    async (dispatch: Dispatch<AnyAction>): Promise<void> => {
        const body = {
            products,
            purchaseParams,
            vacancyId,
            bid,
            budget,
        };

        try {
            let res;

            if (campaignDraftId) {
                res = await fetcher.put(`/shards/vacancy/auction/update_cart_with_auction_campaign_draft`, {
                    ...body,
                    campaignDraftId,
                });
            } else {
                res = await fetcher.post('/shards/vacancy/auction/create_cart_with_auction_campaign_draft', body);
            }

            const { data } = res;

            if (data.purchaseUrl) {
                dispatch(push(data.purchaseUrl));
            } else {
                addNotification(defaultError);
            }
        } catch (e) {
            addNotification(defaultError);
        }
    };

export const createClickmeAuctionCampaignWithoutPurchase =
    (
        vacancyId: string,
        bid: number,
        budget: number,
        onSuccess: (createdAuctionData: CreatedAuctionData) => void,
        onConflictError: () => void,
        addNotification: AddNotification
    ) =>
    (): Promise<void> => {
        return fetcher
            .post('/shards/vacancy/auction/create_auction_campaign_without_purchase', {
                vacancyId,
                bid,
                budget,
            })
            .then(
                ({ data }) => {
                    onSuccess(data);
                },
                ({ response }: AxiosError) => {
                    if (response?.status === CONFLICT) {
                        addNotification(auctionError, { props: { errorType: AuctionErrorType.AlreadyCreated } });
                        onConflictError();
                    } else {
                        addNotification(defaultError);
                    }
                }
            );
    };

export const createCartWithChangedClickmeCampaign =
    (
        products: ProductType[],
        purchaseParams: Record<string, string> = {},
        vacancyId: string,
        bid: number,
        budget: number,
        campaignId: number,
        addNotification: AddNotification
    ) =>
    (dispatch: Dispatch<AnyAction>): Promise<void> =>
        fetcher
            .put('/shards/vacancy/auction/create_cart_with_changed_clickme_campaign', {
                products,
                purchaseParams,
                vacancyId,
                bid,
                budget,
                campaignId,
            })
            .then(
                ({ data }) => {
                    if (data.purchaseUrl) {
                        dispatch(push(data.purchaseUrl));
                    } else {
                        addNotification(defaultError);
                    }
                },
                () => {
                    addNotification(defaultError);
                }
            );

export const changedClickmeCampaign =
    (
        vacancyId: string,
        campaignId: number,
        bid: number,
        budget: number,
        callback: (auction: AuctionData) => void,
        addNotification: AddNotification,
        onError?: () => void
    ) =>
    (): Promise<void> =>
        fetcher
            .put('/shards/vacancy/auction/save_auction_campaign', null, {
                params: {
                    vacancyId,
                    campaignId,
                    budget,
                    bid,
                },
            })
            .then(
                ({ data }) => {
                    if (data) {
                        if (callback) {
                            callback(data);
                        }
                    } else {
                        addNotification(defaultError);
                        onError?.();
                    }
                },
                () => {
                    addNotification(defaultError);
                    onError?.();
                }
            );

export const changeAndRestoreAuctionCampaign =
    (
        vacancyId: string,
        campaignId: number,
        bid: number,
        budget: number,
        callback: (campaign: Partial<CurrentVacancyAuctionCampaign>) => void,
        onError: () => void,
        addNotification: AddNotification
    ) =>
    (): Promise<void> =>
        fetcher
            .put('/shards/vacancy/auction/save_and_restore_auction_campaign', null, {
                params: {
                    vacancyId,
                    campaignId,
                    budget,
                    bid,
                },
            })
            .then(
                ({ data }) => {
                    if (data) {
                        addNotification(saveSuccessfullyAuctionModalNotification);
                        if (callback) {
                            // в data не приходит bid, но в комопонентах мы используем самое свежее значение,
                            // поэтому примешиваем в ответ
                            // хорошо бы чтобы бэк это возвращал и нам не надо было делать "примесь"
                            callback({ ...data, bid });
                        }
                    } else {
                        addNotification(defaultError);
                        onError?.();
                    }
                },
                () => {
                    addNotification(defaultError);
                    onError?.();
                }
            );
