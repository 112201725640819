import { useDispatch } from 'react-redux';

import Analytics, { ElementShownAnchor } from '@hh.ru/analytics-js';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import ConversionNumber from 'bloko/blocks/conversion';
import { H3 } from 'bloko/blocks/header';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format, formatToReactComponent } from 'bloko/common/trl';

import { BillingPlatform, DBAccessProductType } from 'lux/models/price/product.types';
import { Action } from 'lux/models/search/resume/resumeSearchResult';
import { createCart } from 'lux/requests/price/cart';
import paths from 'src/app/routePaths';
import ConversionDate from 'src/components/Conversion/DateOnly';
import FormatMoney from 'src/components/FormatMoney';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { getContactsCountForPlatform } from 'src/utils/price/products';

import getAnalyticsParams, { AnalyticParams } from 'src/components/ResumeSearch/BuyContacts/getAnalyticsParams';

const sendShownVacancySimilarBuyContacts = (element: HTMLElement, analyticsParams: AnalyticParams) =>
    Analytics.sendHHEventElementShown(element, {
        elementName: 'minResumeAccessPrice',
        ...analyticsParams,
    });

const sendClickVacancySimilarBuyContacts = (analyticsParams: AnalyticParams) =>
    Analytics.sendHHEventButtonClick('resume-buy-access', analyticsParams);

const TrlKeys = {
    suitable: 'search.resume.suitable.buy',
    buttonBuy: 'search.resume.suitable.buy.button',
    activate: 'search.resume.suitable.activate',
    buttonActivate: 'search.resume.suitable.activate.button',
    timer: 'search.resume.suitable.timer',
    buyResumeAccess: {
        message: 'search.resume.access.message',
        profRoleGroups: 'search.resume.access.profRoleGroups',
        contactsCount: 'search.resume.access.contactsCount',
        buyFor: 'search.resume.access.buyFor',
        contacts: 'price.dbaccess.contactsCount',
        contactsOnHh: 'price.dbaccess.contactsCount.on.HH',
        contactsOnZp: 'price.dbaccess.contactsCount.on.ZP',
    },
    // Это произошло из-за legacy кода, который много категоризировал как немного и наборот
    daysSome: 'days.many',
    daysOne: 'days.one',
    daysMany: 'days.some',
};

const BuyContacts: TranslatedComponent = ({ trls }) => {
    const employerId = useSelector((state) => state.employerId);
    const isSimilarSearch = useSelector((state) => state.resumeSearchResult?.isSimilarSearch);
    const vacancySimilarBuyContacts = useSelector((state) => state.resumeSearchResult?.vacancySimilarBuyContacts);
    const search = useSelector((state) => state.router.location.search);
    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    if (!isSimilarSearch) {
        return null;
    }

    if (vacancySimilarBuyContacts?.action === Action.Apply) {
        return (
            <div className="resumesearch__dialog">
                <Text>
                    {trls[TrlKeys.activate]}
                    <div className="resumesearch__action">
                        <Button
                            kind={ButtonKind.Success}
                            Element={Link}
                            to={`${paths.resumeSearch}/similar/apply${search}`}
                        >
                            {trls[TrlKeys.buttonActivate]}
                        </Button>
                    </div>
                </Text>
            </div>
        );
    }

    if (vacancySimilarBuyContacts?.daysLost) {
        return (
            <div className="resumesearch__dialog">
                <Text>
                    {trls[TrlKeys.timer]}{' '}
                    <ConversionNumber
                        value={parseInt(vacancySimilarBuyContacts.daysLost, 10)}
                        one={trls[TrlKeys.daysOne]}
                        some={trls[TrlKeys.daysSome]}
                        many={trls[TrlKeys.daysMany]}
                    />
                </Text>
            </div>
        );
    }

    if (vacancySimilarBuyContacts?.action === Action.Buy && vacancySimilarBuyContacts?.product) {
        const { currency, price } = vacancySimilarBuyContacts.product;

        const product = vacancySimilarBuyContacts.product;
        const contactsCountHh = getContactsCountForPlatform(product, BillingPlatform.HH);
        const contactsCountZp = getContactsCountForPlatform(product, BillingPlatform.ZP);
        const hasContactsOnBothPlatforms = Boolean(contactsCountHh) && Boolean(contactsCountZp);
        const contactsCount = contactsCountHh || contactsCountZp;
        const regionTrl = product.regionTrl;
        return (
            <ElementShownAnchor fn={sendShownVacancySimilarBuyContacts} {...getAnalyticsParams(product, employerId)}>
                <div className="resumesearch__dialog">
                    <div>
                        <H3>{trls[TrlKeys.buyResumeAccess.message]}</H3>
                        <VSpacing base={3} />
                        <Text>
                            <div>
                                <ConversionDate
                                    value={Number(product.period)}
                                    shouldShowInHours={Number(product.period) === 1}
                                />
                                {Boolean(regionTrl) && `, ${regionTrl}`}
                                {`, ${trls[TrlKeys.buyResumeAccess.profRoleGroups]}`}
                            </div>
                            {hasContactsOnBothPlatforms ? (
                                <div>
                                    {`${format(trls[TrlKeys.buyResumeAccess.contacts], {
                                        '{0}': String(contactsCountHh),
                                    })} ${trls[TrlKeys.buyResumeAccess.contactsOnHh]}, ${format(
                                        trls[TrlKeys.buyResumeAccess.contacts],
                                        { '{0}': String(contactsCountZp) }
                                    )} ${trls[TrlKeys.buyResumeAccess.contactsOnZp]}`}
                                </div>
                            ) : (
                                Boolean(contactsCount) && (
                                    <div>
                                        {format(trls[TrlKeys.buyResumeAccess.contactsCount], {
                                            '{0}': String(contactsCount),
                                        })}
                                    </div>
                                )
                            )}
                        </Text>
                        <div className="resumesearch__action">
                            <Button
                                data-qa="similar-resumesearch-buy-resume-access"
                                kind={ButtonKind.Success}
                                onClick={() => {
                                    sendClickVacancySimilarBuyContacts(getAnalyticsParams(product, employerId));
                                    void dispatch(
                                        createCart(
                                            [vacancySimilarBuyContacts.product as DBAccessProductType],
                                            addNotification
                                        )
                                    );
                                }}
                            >
                                {formatToReactComponent(trls[TrlKeys.buyResumeAccess.buyFor], {
                                    '{0}': (
                                        <FormatMoney currency={currency} inCents>
                                            {price}
                                        </FormatMoney>
                                    ),
                                })}
                            </Button>
                        </div>
                    </div>
                </div>
            </ElementShownAnchor>
        );
    }

    return null;
};

export default translation(BuyContacts);
