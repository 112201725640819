import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind, ButtonScale, ButtonType } from 'bloko/blocks/button';
import { LoaderScaleSmall, IconColor } from 'bloko/blocks/icon';
import BlokoLink from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';
import Form from 'src/components/Form';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

const STATE = {
    SAVING: 'saving',
    SAVED: 'saved',
};

const SavedSearchSubscription = ({ trls }) => {
    const query = useSelector((state) => state.resumeSearchResult.queries.autosearch);
    const saveSearch = useSelector((state) => state.resumeSearchResult.saveSearch);

    const [state, setState] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const location = useSelector((state) => state.router.location);
    const { addNotification } = useNotification();

    useEffect(() => {
        setDisabled(false);
    }, []);

    useEffect(() => {
        setState(null);
    }, [location]);

    if (!saveSearch) {
        return null;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setState(STATE.SAVING);
        try {
            await fetcher.post(`/resumesavedsearch/save?${query}`);
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
            setState(null);
            return;
        }
        setState(STATE.SAVED);
    };

    return (
        <>
            <div className="employer-snippet">
                <Form onSubmit={handleSubmit} data-qa="autosearch-subscribe__form">
                    {state !== STATE.SAVED ? (
                        <div className="saved-search-subscription">
                            <div>
                                <Text strong>{trls[SavedSearchSubscription.trls.teaser1]}</Text>
                                <VSpacing base={1} />
                                <div>{trls[SavedSearchSubscription.trls.teaser2]}</div>
                            </div>
                            <div>
                                <Button
                                    type={ButtonType.Submit}
                                    data-qa="autosearch-subscribe__submit"
                                    kind={ButtonKind.Success}
                                    disabled={disabled}
                                    scale={ButtonScale.Small}
                                    loading={state === STATE.SAVING && <LoaderScaleSmall initial={IconColor.Blue60} />}
                                >
                                    {trls[SavedSearchSubscription.trls.subscribe]}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div data-qa="vacancy-serp__search-saved">
                            {trls[SavedSearchSubscription.trls.saved]}
                            <BlokoLink disableVisited Element={Link} to="/resumesavedsearch">
                                {trls[SavedSearchSubscription.trls.autosearch]}
                            </BlokoLink>
                        </div>
                    )}
                </Form>
            </div>
            <VSpacing base={4} />
        </>
    );
};

SavedSearchSubscription.propTypes = {
    trls: PropTypes.object,
};

SavedSearchSubscription.trls = {
    teaser1: 'serp.savedSearch.teaser.1',
    teaser2: 'serp.savedSearch.teaser.2',
    subscribe: 'search.vacancy.savedSearch.subscribe',
    saved: 'vacancySearchResults.saveSearchPreference.searchSavedIn',
    autosearch: 'vacancySearchResults.saveSearchPreference.autosearch',
};

export default translation(SavedSearchSubscription);
