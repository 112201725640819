import { useRef, useEffect } from 'react';

import { FormItem } from 'bloko/blocks/form';
import Gap from 'bloko/blocks/gap';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import { UserType } from 'lux/models/userType';
import CatalogLinks from 'src/components/CatalogSearch';
import ExtraFilterCatalogLinks from 'src/components/CatalogSearch/ExtraFilters';
import InfoBlockSubstrate, { InfoBlockSubstrateKind } from 'src/components/InfoBlockSubstrate';
import GridColumn from 'src/components/MagritteRedesignComponents/GridColumn';
import NovaFilters from 'src/components/NovaFilters';
import NovaFilterUpdateContextProvider from 'src/components/NovaFilters/NovaFilterUpdateContext';
import MagritteMobileForm from 'src/components/NovaFilters/components/Magritte/MobileForm';
import Pagination from 'src/components/Pagination';
import AdditionalCheckSnippet from 'src/components/ResumeAdditionalCheck/AdditionalCheckSnippet';
import ResumeCardImprovedOnboardingBanner from 'src/components/ResumeCardImprovedOnboardingBanner';
import AutoInviteSnippet from 'src/components/ResumeSearch/AutoInviteSnippet';
import PickVacancyBanner from 'src/components/ResumeSearch/PickVacancyBanner';
import RegistrationAnonActionInSerp from 'src/components/ResumeSearch/RegistrationAnonActionInSerp';
import SavedSearchSubscription from 'src/components/ResumeSearch/SavedSearchSubscription';
import SearchResults from 'src/components/ResumeSearch/SearchResults';
import Settings from 'src/components/ResumeSearch/Settings';
import SearchPreferenceButton from 'src/components/SearchSorts/SearchPreferenceButton';
import StickySidebar, { StickySidebarAndContent } from 'src/components/StickySidebar';
import UnderSearchResultBanner from 'src/components/UnderSearchResultBanner';
import useMagritte from 'src/hooks/useMagritte';
import useNovaFiltersOrderForResume from 'src/hooks/useNovaFiltersOrderForResume';
import { useSelector } from 'src/hooks/useSelector';

const ResumeSearchItems = () => {
    const {
        resumes,
        criteria,
        paging,
        settings: { filtersOnTheLeft },
        isClustersEnabled,
        withHiddenResumes,
    } = useSelector((state) => state.resumeSearchResult);
    const isMagritte = useMagritte();

    const resumeSerps = useRef(null);
    const showSearchPreference = useSelector(({ searchPreference }) => searchPreference.isShown);
    const isAnonymous = useSelector(({ userType }) => UserType.Anonymous === userType);
    const { filtersOrder, additionalFiltersOrder } = useNovaFiltersOrderForResume();
    const catalogLinks = useSelector((state) => state.catalogLinks);
    const shouldHideSettings = withHiddenResumes && resumes.length === 0;
    const showResumeCardImprovedOnboardingBanner = useSelector((state) => state.showResumeCardImprovedOnboardingBanner);
    const isPickVacancyBannerShow = useSelector((state) => state.pickVacancyOnResumeSearch.isBannerShow);
    const additionalCheck = useSelector(({ additionalCheck }) => additionalCheck);

    const breakpoint = useBreakpoint();
    const isXsOrS = [Breakpoint.XS, Breakpoint.S].includes(breakpoint);

    useEffect(() => {
        if (!isMagritte && showSearchPreference) {
            resumeSerps.current.scrollIntoView();
        }
    }, [isMagritte, showSearchPreference]);

    if (!isClustersEnabled) {
        return (
            <div className="resume-serp">
                <GridColumn xs="4" s="8" m="12" l={isMagritte ? '9' : '13'}>
                    <div>
                        {!shouldHideSettings && (
                            <FormItem>
                                <Settings />
                            </FormItem>
                        )}
                        {additionalCheck ? (
                            <>
                                <InfoBlockSubstrate
                                    data-qa="resume-demo-block-addtional-check"
                                    kind={InfoBlockSubstrateKind.Warning}
                                >
                                    <AdditionalCheckSnippet />
                                </InfoBlockSubstrate>
                                <VSpacing base={4} />
                            </>
                        ) : (
                            showResumeCardImprovedOnboardingBanner && <ResumeCardImprovedOnboardingBanner />
                        )}
                        {isPickVacancyBannerShow && (
                            <>
                                <PickVacancyBanner />
                                <VSpacing base={4} />
                            </>
                        )}
                        {!shouldHideSettings && <SavedSearchSubscription />}
                        <AutoInviteSnippet />
                        <SearchResults />
                        <Gap top>
                            <Pagination {...paging} />
                        </Gap>
                        <UnderSearchResultBanner />
                    </div>
                </GridColumn>
            </div>
        );
    }

    const showNovaFiltersOnTheRight = !filtersOnTheLeft?.value;

    const filters = (
        <NovaFilters
            criteria={criteria}
            filtersOrder={filtersOrder}
            additionalFiltersOrder={additionalFiltersOrder}
            withCounter={true}
            isMagritte={isMagritte}
        />
    );

    const filtersWithGrid = (
        <GridColumn xs={showSearchPreference ? '4' : '0'} s={showSearchPreference ? '8' : '0'} m="3" l="3">
            {filters}
        </GridColumn>
    );

    const content = (
        <GridColumn
            xs={!isMagritte && showSearchPreference ? '0' : '4'}
            s={!isMagritte && showSearchPreference ? '0' : '8'}
            m="9"
            l={isMagritte ? '9' : '13'}
        >
            {isAnonymous && <RegistrationAnonActionInSerp />}
            {!shouldHideSettings && (
                <FormItem>
                    <Settings />
                </FormItem>
            )}
            {additionalCheck && (
                <>
                    <InfoBlockSubstrate
                        data-qa="resume-demo-block-addtional-check"
                        kind={InfoBlockSubstrateKind.Warning}
                    >
                        <AdditionalCheckSnippet />
                    </InfoBlockSubstrate>
                    <VSpacing base={4} />
                </>
            )}
            {showResumeCardImprovedOnboardingBanner && (
                <>
                    <ResumeCardImprovedOnboardingBanner />
                    <VSpacing base={4} />
                </>
            )}
            {isPickVacancyBannerShow && (
                <>
                    <PickVacancyBanner />
                    <VSpacing base={4} />
                </>
            )}
            {!shouldHideSettings && <SavedSearchSubscription />}
            <AutoInviteSnippet />
            <SearchResults />
            <VSpacing base={2} />
            <Pagination {...paging} />
            {catalogLinks?.length > 0 && <CatalogLinks queries={catalogLinks} />}
            <ExtraFilterCatalogLinks />
            {resumes.length > 0 && <UnderSearchResultBanner />}
            {!isMagritte && <SearchPreferenceButton />}
        </GridColumn>
    );

    return (
        <NovaFilterUpdateContextProvider>
            <div ref={resumeSerps} className="resume-serp">
                {isXsOrS ? (
                    <>
                        {isMagritte ? <MagritteMobileForm>{filters}</MagritteMobileForm> : filtersWithGrid}
                        {content}
                    </>
                ) : (
                    <StickySidebarAndContent isGridContent={isMagritte}>
                        {!showNovaFiltersOnTheRight && <StickySidebar>{filtersWithGrid}</StickySidebar>}
                        <StickySidebar>{content}</StickySidebar>
                        {showNovaFiltersOnTheRight && <StickySidebar>{filtersWithGrid}</StickySidebar>}
                    </StickySidebarAndContent>
                )}
            </div>
        </NovaFilterUpdateContextProvider>
    );
};

export default ResumeSearchItems;
