import { Platform } from 'lux/models/locale.types';

import { usePlatform } from 'src/hooks/usePlatform';

const usePropertiesBundle = (): 'HH' | 'ZP' => {
    const platform = usePlatform();
    return platform === Platform.HeadHunter ? 'HH' : 'ZP';
};

export default usePropertiesBundle;
