import React, { FC } from 'react';

import { AdsExternalBannerProps } from 'lux/models/banners/AdsExternalBanner.types';
import AdsExternalBanner from 'src/components/Banner/AdsExternalBanner';
import { useSelector } from 'src/hooks/useSelector';

const bannerPositions = [3, 9] as const;
type BannerPosition = (typeof bannerPositions)[number];
interface GetPositionToBannersMap {
    (banners: Record<string, AdsExternalBannerProps[]>, position: BannerPosition): Record<
        string,
        AdsExternalBannerProps[]
    >;
}

const isBannerPosition = (position: number): position is BannerPosition => bannerPositions.includes(position);

const getPositionToBannersMap: GetPositionToBannersMap = (banners, position) => {
    const bannersMap = {
        3: {
            bannerL: banners['serp-first-l'],
            bannerM: banners['serp-first-m'],
            bannerS: banners['serp-first-s'],
            bannerXS: banners['serp-first-xs'],
        },
        9: {
            bannerL: banners['serp-second-l'],
            bannerM: banners['serp-second-m'],
            bannerS: banners['serp-second-s'],
            bannerXS: banners['serp-second-xs'],
        },
    };
    return bannersMap[position];
};

interface SearchResultBannersType {
    position: number;
    banners: Record<string, AdsExternalBannerProps[]>;
}

const SearchResultBanners: FC<SearchResultBannersType> = ({ position, banners }) => {
    const adsSearchParams = useSelector((state) => state.adsSearchParams);
    if (!isBannerPosition(position)) {
        return null;
    }
    const { bannerL, bannerM, bannerS, bannerXS } = getPositionToBannersMap(banners, position);
    const adsPositionInfo = `${bannerPositions.indexOf(position) + 1}_${position}`;
    return (
        <>
            {bannerL?.length > 0 && (
                <div className="banner-place-wrapper_show-on-l">
                    <div className="serp-special">
                        <AdsExternalBanner
                            {...bannerL[0]}
                            bannerAdTargeting={adsSearchParams}
                            positionInfo={adsPositionInfo}
                        />
                    </div>
                </div>
            )}
            {bannerM?.length > 0 && (
                <div className="banner-place-wrapper_show-on-m">
                    <div className="serp-special">
                        <AdsExternalBanner
                            {...bannerM[0]}
                            bannerAdTargeting={adsSearchParams}
                            positionInfo={adsPositionInfo}
                        />
                    </div>
                </div>
            )}
            {bannerS?.length > 0 && (
                <div className="banner-place-wrapper_show-on-s">
                    <div className="serp-special">
                        <AdsExternalBanner
                            {...bannerS[0]}
                            bannerAdTargeting={adsSearchParams}
                            positionInfo={adsPositionInfo}
                        />
                    </div>
                </div>
            )}
            {bannerXS?.length > 0 && (
                <div className="banner-place-wrapper_show-on-xs">
                    <div className="serp-special">
                        <AdsExternalBanner
                            {...bannerXS[0]}
                            bannerAdTargeting={adsSearchParams}
                            positionInfo={adsPositionInfo}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default SearchResultBanners;
