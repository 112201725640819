import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind, ButtonScale } from 'bloko/blocks/button';
import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { TopLevelSite } from 'lux/models/topLevelSite';
import { UserType } from 'lux/models/userType';
import RegistrationMessage from 'src/components/ResumeSearch/RegistrationMessage';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    buy: 'resumesSearch.normalResults.buy',
};

const RegistrationAction: TranslatedComponent = ({ trls }) => {
    const isApplicant = useSelector(({ userType }) => UserType.Applicant === userType);
    const isEmployer = useSelector(({ userType }) => UserType.Employer === userType);
    const isAnonimous = useSelector(({ userType }) => UserType.Anonymous === userType);
    const stats = useSelector(({ resumeSearchResult }) => resumeSearchResult?.stats);
    const buyButtonVisible = useSelector((state) => state.topLevelSite !== TopLevelSite.AZ);

    if (!stats?.normalSearchResults) {
        return null;
    }

    const { normalSearchResults, totalUngroupedResults } = stats;
    if (isAnonimous) {
        return null;
    }
    return (
        <div className="resumesearch__dialog" data-qa="resume-serp__resumes-count-found-after-registration">
            <div className="resumesearch__dialog-item">
                <Text>
                    <RegistrationMessage
                        isApplicant={isApplicant}
                        isEmployer={isEmployer}
                        normalSearchResults={normalSearchResults}
                        totalUngroupedResults={totalUngroupedResults}
                    />
                </Text>
            </div>
            {isEmployer && buyButtonVisible && (
                <div className="resumesearch__dialog-item">
                    <Button
                        Element={Link}
                        kind={ButtonKind.Success}
                        to="/price"
                        scale={ButtonScale.Small}
                        data-qa="resume-serp__action-after-search"
                    >
                        {trls[TrlKeys.buy]}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default translation(RegistrationAction);
